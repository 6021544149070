import React from 'react';
import { Link } from 'gatsby';

const Header = ({ title }) => {
    return (
        <header className="header">
            <nav className="header__nav" role="navigation">
                <ul className="header__nav__list">
                    <li className="header__nav__item logo">
                        <Link to={`/`}>Slovo</Link>
                    </li>
                    {/* <li className="header__nav__item blog">
                        <Link to={`/blog`}>Blog</Link>
                    </li> */}
                </ul>
            </nav>
        </header>
    );
};

export default Header;
