import React from 'react';
import * as Sentry from '@sentry/browser';
import Cookie from './cookie';
import Header from './header';

import './layout.scss';

Sentry.init({
    dsn:
        'https://6f94e52fa6394f0bafd2c1746b78488f@o387263.ingest.sentry.io/5222266',
});

const Layout = ({ location, title, children }) => {
    return (
        <div className="body">
            <Header title={title} />
            <main>{children}</main>
            <footer>
                <a href="/privacy">Privacy Policy</a>
                <a href="/termsconditions">Terms and Conditions</a>
                <a href="/contact">Contact</a>
                {/* © {new Date().getFullYear()} */}
            </footer>
            <Cookie />
        </div>
    );
};

export default Layout;
