import React from 'react';
import CookieConsent, { Cookies } from 'react-cookie-consent';

import './cookie.scss';

const Cookie = () => {
    return (
        <CookieConsent
            disableStyles={true}
            location="bottom"
            buttonText="ACCEPT"
            containerClasses="cookie-bar"
            contentClasses="cookie-bar__content"
            buttonClasses="cookie-bar__button"
            onAccept={() => {
                Cookies.set('gatsby-gdpr-google-analytics', true);
                Cookies.set('gatsby-gdpr-facebook-pixel', true);
            }}
            // declineButtonText="Decline"
            // enableDeclineButton
            // onDecline={() => {
            //     Cookies.set('gatsby-gdpr-google-analytics', false);
            //     Cookies.set('gatsby-gdpr-facebook-pixel', false);
            // }}
        >
            This website uses cookies We use cookies to personalise content and
            ads, to provide social media features and to analyse our traffic. We
            also share information about your use of our site with our social
            media, advertising and analytics partners who may combine it with
            other information that you’ve provided to them or that they’ve
            collected from your use of their services.
        </CookieConsent>
    );
};

export default Cookie;
